import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  icon,
  onClick,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={clsx(
        "inline-flex items-center gap-1 underline decoration-pink decoration-2 underline-offset-4 duration-200 hover:decoration-orange group-hover:decoration-orange",
        _className
      )}
    >
      {/* back arrow */}
      {icon && reverse && (
        <Icon
          name={icon || "chevron"}
          className="icon h-2.5 w-2.5 shrink-0 rotate-90 text-text-color"
        />
      )}
      {/* label */}
      <span>{children}</span>
      {/* forward arrow */}
      {icon && !reverse && (
        <Icon
          name={icon || "chevron"}
          className="icon h-2.5 w-2.5 shrink-0 -rotate-90 text-text-color"
        />
      )}
    </AppLink>
  );
};

export default ButtonAlt;
