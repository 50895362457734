import { useStaticQuery, graphql } from "gatsby";
import { resolveEvent } from "@dataResolvers";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query RecentEventsQuery {
    craft {
      entries(section: "events", orderBy: "eventDate") {
        ... on Craft_events_default_Entry {
          title
          image0 {
            ...ImageFragment
          }
          url
          eventDate
          noindex: boolean5
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const events = craft.entries
    ?.filter(e => new Date(e.eventDate) > new Date() && !e.noindex)
    ?.map(resolveEvent)
    ?.slice(0, 3);

  return events;
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
