import React from "react";
import { Text } from "@atoms";

const CbCopy = ({ copy }) => {
  return (
    <div className="my-6 w-full md:my-10">
      <Text richText>{copy}</Text>
    </div>
  );
};

export default CbCopy;
