/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React, { useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useAppState } from "@state";
import { Meta } from "@atoms";

const PageContainer = ({ children, className, path, meta, password }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();
  const [visible, setVisible] = useState(!password);

  const variants = {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <motion.main
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.333,
      }}
      variants={variants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
      className={clsx(className, "min-h-screen overflow-hidden")}
    >
      {!visible && (
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center gap-2 bg-black pt-24 text-center text-white">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="password" className="text-sm uppercase">
            Enter Password
          </label>
          <input
            name="password"
            type="text"
            className="p-2 text-black"
            onChange={e =>
              e.target.value === password ? setVisible(true) : null
            }
          />
        </div>
      )}
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      {visible && <>{children}</>}
      {meta && <Meta {...meta} />}
    </motion.main>
  );
};

export default PageContainer;
