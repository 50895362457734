import React from "react";
import { Text } from "@atoms";
import { AppLink } from "@base";
import Socials from "./Socials";

const Column = ({ text, url, children, array, socials, i }) => {
  if (array?.length) {
    return (
      <li className="flex-1/2-4 md:flex-auto" key={i}>
        <ul className="space-y-2">
          {array?.map((link, _i) => {
            return (
              <li key={`subArray--${i}-${_i + 1}`}>
                <AppLink
                  to={link?.url}
                  className="duration-400 flex items-center transition hover:opacity-70"
                >
                  <Text variant="lg" className="font-bold text-highlight">
                    {link?.text}
                  </Text>
                </AppLink>
              </li>
            );
          })}
          {socials && <Socials socials={socials} />}
        </ul>
      </li>
    );
  }
  return (
    <li key={i} className="flex-1/2-4 md:flex-auto">
      <AppLink
        to={url}
        className="duration-400 mb-4 flex items-center transition hover:opacity-70"
      >
        <Text variant="xl" className="font-bold text-highlight">
          {text}
        </Text>
      </AppLink>
      {children?.length > 0 && (
        <ul className="space-y-2">
          {children?.map((subLink, _i) => {
            const { text: _text, url: _url } = subLink;
            return (
              <li key={`subfooter-link-${i}--${_i + 1}`}>
                <AppLink
                  to={_url}
                  className="text-xs text-text-color transition duration-200 hover:text-highlight"
                >
                  <Text variant="xs">{_text}</Text>
                </AppLink>
              </li>
            );
          })}
          {socials && <Socials socials={socials} />}
        </ul>
      )}
    </li>
  );
};

export default Column;
