import React from "react";
import { Icon } from "@atoms";
import { AppLink } from "@base";

const Socials = ({ socials }) => {
  return (
    <li className="flex items-center gap-2">
      {socials?.map((s, i) => {
        const { platform, accounturl } = s;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <AppLink to={accounturl} key={i}>
            <Icon
              name={platform}
              className="duration-400 h-5 w-5 text-highlight transition hover:opacity-70 md:h-3 md:w-3"
              fitHeight={platform === "facebook"}
            />
          </AppLink>
        );
      })}
    </li>
  );
};

export default Socials;
