/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable global-require */

// import styles index
import "./src/css/index.css";
import { AnimatePresence } from "framer-motion";

import React from "react";
import { Default } from "@layout";
import ErrorBoundary from "@organisms/ErrorBoundary";
import { AppStateProvider } from "@state";

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  return (
    <AnimatePresence mode="wait" initial>
      <Default context={pageContext}>{element}</Default>
    </AnimatePresence>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <AppStateProvider>{element}</AppStateProvider>
    </ErrorBoundary>
  );
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line import/no-extraneous-dependencies
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};
