import React, { useEffect } from "react";
import { HtmlParser } from "@base";

const EmbedCode = ({ html, javascript }) => {
  useEffect(() => {
    // eslint-disable-next-line no-eval
    if (javascript) eval(javascript);
  }, []);

  return <HtmlParser html={html} />;
};

export default EmbedCode;
