import { useStaticQuery, graphql } from "gatsby";
import { resolveImage } from "@dataResolvers";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query CompaniesQuery {
    craft {
      globalSet(handle: "defaults") {
        ... on Craft_defaults_GlobalSet {
          companies {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { companies } = craft.globalSet;

  return {
    companies: companies?.map(resolveImage),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
