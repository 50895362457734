import resolveImage from "./resolveImage";

const resolveContentBuilder = blocks => {
  const resolvedblocks = [];
  blocks?.forEach(block => {
    const { type, uid } = block;
    switch (type) {
      case "blockQuote":
        resolvedblocks.push({
          type,
          uid,
          quote: block.quote?.[0]?.descriptor0,
          author: block.quote?.[0]?.title,
          title: block.quote?.[0]?.metaTitle,
          headshot: resolveImage(block.quote?.[0]?.image0),
          link: block.linkObject,
        });
        break;
      case "button":
        resolvedblocks.push({
          type,
          uid,
          link: block.link,
          style: block.style ? "button" : "link",
        });
        break;
      case "copy":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "cta":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          description: block.description,
          link: block.linkObject,
        });
        break;
      case "embedCode":
        resolvedblocks.push({
          ...block,
        });
        break;
      case "formAssemblyForm":
        resolvedblocks.push({
          ...block,
        });
        break;
      case "lead":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "logoGrid":
        resolvedblocks.push({
          type,
          uid,
          logos: block.logos?.map(l => {
            return {
              ...l,
              logo: resolveImage(l?.logo),
            };
          }),
        });
        break;
      case "newsletterForm":
        resolvedblocks.push({
          ...block,
        });
        break;
      case "images":
        resolvedblocks.push({
          type,
          uid,
          images: block.images,
          caption: block.caption,
        });
        break;
      case "video":
        resolvedblocks.push({
          type,
          uid,
          videoUrl: block.videoUrl,
          loop: block.loop,
          loopTimes: block.loopTimes,
          caption: block.caption,
        });
        break;
      default:
        resolvedblocks.push({ type, uid, ...block });
        break;
    }
  });

  return resolvedblocks;
};

export default resolveContentBuilder;
