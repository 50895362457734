import React from "react";
import parse from "html-react-parser";
import { Script } from "gatsby";

// todo: add manual script cleanup

const defaults = {
  // ? this function automatically handles inconsistent returns by design
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    switch (domNode.name) {
      case "script":
        return <Script src={domNode.attribs.src} />;
      default:
        break;
    }
  },
};

const Html = ({ html, options = {} }) => {
  if (html) return <>{parse(html, { ...defaults, ...options })}</>;
  return null;
};

export default Html;
