import { useStaticQuery, graphql } from "gatsby";
import { resolveLinks, resolveImage } from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          footerLinks {
            ... on Craft_footerLinks_linkList_BlockType {
              type: typeHandle
              links {
                ... on Craft_links_BlockType {
                  linkObject {
                    url
                    text
                  }
                }
              }
            }
            ... on Craft_footerLinks_linkWithADropdown_BlockType {
              type: typeHandle
              linkObject {
                url
                text
              }
              children: dropdown {
                ... on Craft_dropdown_BlockType {
                  subLink {
                    url
                    text
                  }
                }
              }
            }
          }
          social {
            ... on Craft_social_account_BlockType {
              platform
              accounturl
            }
          }
          copyrightText: descriptor0
          privacyPolicy: link0 {
            text
            url
          }
          images: images0 {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { footerLinks, social, copyrightText, privacyPolicy, images } =
    craft.globalSet;

  const footer = footerLinks?.map(resolveLinks);
  const badges = images?.map(resolveImage);

  return { footer, social, copyrightText, privacyPolicy, badges };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
