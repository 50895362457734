import React from "react";
import clsx from "clsx";
import { unwidower } from "@utils";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag = "span",
  dewidow = false,
}) => {
  let Tag = tag;
  let classes = "text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes = "font-header text-3xl md:text-h1 leading-tight md:leading-none";
      break;
    case "h2":
      Tag = "h2";
      classes = "font-header text-2.5xl md:text-h2 leading-tight";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-header text-2xl md:text-h3 leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-header text-1.5xl md:text-h4 leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-header text-xl md:text-h5 leading-tighter";
      break;
    case "h6":
      Tag = "h6";
      classes = "font-header text-lg md:text-h6 leading-tighter";
      break;
    case "h7":
      Tag = "h6";
      classes = "font-header text-h7 leading-tighter";
      break;
    case "xl":
      classes = "block text-lg md:text-xl leading-normal font-sans";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-sm leading-normal font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "label":
      classes = "font-header text-h7 block";
      break;
    case "nav":
      classes =
        "block text-sm font-bold leading-none font-header text-text-color tracking-0.5";
      break;
    case "massiveHeader":
      classes =
        "font-header text-8xl md:text-10xl leading-tight md:leading-none";
      break;
    case "testimonial":
      classes = "block text-sm sm:text-lg md:text-xl leading-normal font-sans";
      break;
    default:
      classes = "";
      break;
  }

  const proseClasses =
    "prose prose-h2:text-2.5xl md:prose-h2:text-h2 prose-h3:text-2xl md:prose-h3:text-h3 prose-h4:text-1.5xl md:prose-h4:text-h4 prose-h5:text-xl md:prose-h5:text-h5 prose-headings:leading-tighter";

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={clsx(classes, _className, {
            quotes,
            [proseClasses]: richText,
          })}
          dangerouslySetInnerHTML={{ __html: unwidower(child, dewidow) }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={clsx(classes, _className, {
          quotes,
          [proseClasses]: richText,
        })}
      >
        {unwidower(child, dewidow)}
      </Tag>
    );
  });
};

export default Text;
