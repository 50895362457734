import React, { useState } from "react";
import { Input, Fieldset, Text } from "@atoms";
import { useNewsletterData } from "@staticQueries";
import FormContainer from "./FormContainer";

const demoGroup = [
  {
    id: "student",
    label: "Student",
    value: "student",
  },
  {
    id: "adult",
    label: "Adult",
    value: "adult",
  },
];

const NewsletterForm = ({ heading, description }) => {
  const { form: _formText } = useNewsletterData();
  const { heading: defaultHeading, description: defaultDescription } =
    _formText;
  const [form, setForm] = useState({});

  const submitForm = async () => {
    try {
      // Make request to submit form with updated formData

      const data = await fetch("/api/mailchimp", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(form),
      });

      return data.json();
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="bg-gradient-to-t from-pink to-orange-gradient p-6">
      <Text variant="h5" className="mb-4 text-center text-white">
        {heading || defaultHeading}
      </Text>
      {description && (
        <Text variant="sm" className="-mt-2 mb-8 text-center text-white">
          {description || defaultDescription}
        </Text>
      )}

      <FormContainer
        buttonColor="whiteTransparent"
        onSubmit={submitForm}
        formState={[form, setForm]}
        className="space-y-4"
      >
        <Input
          label="Are you a student or an adult?"
          id="kind"
          name="kind"
          group={demoGroup}
          required
          type="radio"
          className="flex justify-center gap-4 text-center font-header text-h7 !normal-case"
        />
        <Fieldset className="grid gap-4 sm:grid-cols-2">
          <Input label="First Name:" id="firstName" name="firstName" required />
          <Input label="Last Name:" id="lastName" name="lastName" required />
        </Fieldset>
        <Input label="Email address:" id="email" name="email" required />
      </FormContainer>
    </div>
  );
};

export default NewsletterForm;
