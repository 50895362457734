import React from "react";
import { EmbedCode } from "@atoms";

const CbEmbed = props => {
  return (
    <div className="my-6 md:my-10">
      <EmbedCode {...props} />
    </div>
  );
};

export default CbEmbed;
