import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import useWindowSize from "@hooks/useWindowSize";
import { motion } from "framer-motion";
import { imageUtils } from "@utils";
import Text from "./Text";

const { buildUrl, defaultParams } = imageUtils;

const Image = ({
  backgroundColor = false,
  objectFit = "cover",
  eager = false,
  draggable = true,
  image,
  ixParams,
  caption,
  fitHeight,
  resolution,
  fill,
  aspectRatio,
  widthAdjust: _widthAdjust = 1,
  preserveAspectRatio,
  className,
}) => {
  const figure = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  const [inView, setInView] = useState(false);
  const [loaded, setLoaded] = useState(eager);
  const [widthAdjust, setWidthAdjust] = useState(_widthAdjust);
  const [sources, setSources] = useState([]);
  const [pr, setPr] = useState(resolution || 1);
  const {
    url,
    height,
    width,
    focalPoint,
    skipBuild,
    eagerUrl,
    title,
    alt,
    hasFocalPoint,
  } = image || {};
  const [fpx, fpy] = hasFocalPoint ? focalPoint : [false, false];
  // calculate aspect ratio
  const ar = aspectRatio ? aspectRatio[0] / aspectRatio[1] : width / height;

  useEffect(() => {
    setPr(resolution || window.devicePixelRatio);
  }, []);

  const finalParams = { ...defaultParams(false, ar, pr), ...ixParams };

  if (fpx && fpy) {
    finalParams["fp-x"] = fpx;
    finalParams["fp-y"] = fpy;
    finalParams.fit = "crop";
    finalParams.crop = "focalpoint";
  }

  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.3,
        type: "tween",
        ease: "easeIn",
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        type: "tween",
        ease: "easeIn",
      },
    },
  };

  useEffect(() => {
    if (figure.current) {
      setWidthAdjust(
        (figure.current.clientWidth / windowSize).toFixed(1) || 0.1
      );
    }
  }, [figure.current, windowSize]);

  useEffect(() => {
    let arm = 1;
    if (figure.current) {
      arm = preserveAspectRatio
        ? 1 / ar
        : (figure.current.clientHeight / figure.current.clientWidth).toFixed(2);
    }

    setSources(
      [360, 500, 800, 1360, 2000].map(size => ({
        url: !skipBuild
          ? buildUrl(url, {
              ...finalParams,
              w: parseInt(size * widthAdjust, 10) || size,
              h:
                parseInt(
                  ar ? arm * size * widthAdjust : size * widthAdjust,
                  10
                ) || size,
            })
          : url,
        size,
      }))
    );
  }, [widthAdjust, url, pr]);

  // load in images after 3s
  useEffect(() => {
    setTimeout(() => {
      setInView(true);
    }, 3000);
  }, []);

  if (!image) return <div />;

  return (
    <motion.figure
      ref={figure}
      className={clsx(className, "w-full overflow-hidden", {
        "absolute inset-0": fill,
        relative: !fill,
        "h-full": fitHeight,
      })}
      onViewportEnter={() => setInView(true)}
    >
      <div
        className={clsx("relative h-full w-full", {
          "absolute inset-0": fill,
          "bg-white": backgroundColor && !loaded,
        })}
        style={{
          paddingTop: 0,
          aspectRatio: fill ? null : ar,
        }}
      >
        <motion.picture
          initial={eager ? null : "hidden"}
          variants={variants}
          animate={(loaded && !eager) || eager ? "visible" : "hidden"}
          viewport={{ once: true }}
        >
          {/* load srcset */}
          {(inView || eager) && (
            <>
              {sources.map((s, i) => (
                <source
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${s.url}-${i}`}
                  srcSet={s.url}
                  media={`(max-width: ${sources?.[i + 1]?.size || 4000}px)`}
                />
              ))}
            </>
          )}
          {/* todo: fix alt tag fallback */}
          <img
            title={title}
            width="100%"
            height="100%"
            onLoad={() => setLoaded(true)}
            src={loaded || eager ? eagerUrl : null}
            draggable={draggable}
            alt={alt || title || "image"}
            className={clsx("h-full w-full", {
              "absolute inset-0 object-cover": objectFit === "cover",
              "absolute inset-0 object-contain": objectFit === "contain",
            })}
          />
        </motion.picture>
      </div>
      {caption && (
        <figcaption>
          <Text variant="caption" className="text-black">
            {caption}
          </Text>
        </figcaption>
      )}
    </motion.figure>
  );
};

export default Image;
