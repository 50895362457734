import React from "react";
import { Image } from "@atoms";
import { AppLink } from "@base";

const CbLogoGrid = ({ logos }) => {
  return (
    <div className="my-6 md:my-10">
      <div className="flex flex-wrap items-center justify-center gap-8">
        {logos?.map(item => {
          const { uid, link, logo } = item;
          if (link?.url) {
            return (
              <AppLink key={uid} to={link.url} className="h-24 w-1/3-gap-8">
                <Image
                  image={logo}
                  fitHeight
                  ixParams={{ fit: "clip" }}
                  objectFit="contain"
                />
              </AppLink>
            );
          }
          return (
            <div key={uid} className="h-24 w-1/3-gap-8">
              <Image
                image={logo}
                fitHeight
                ixParams={{ fit: "clip" }}
                objectFit="contain"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CbLogoGrid;
