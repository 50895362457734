import resolveImage from "./resolveImage";

const resolvePageBuilder = (blocks = [], isDark = true) => {
  const resolvedblocks = [];
  let reverse = true;
  let headingAbove = false;
  const count = null;
  blocks.forEach((block, i, array) => {
    const { type, uid } = block;

    switch (type) {
      case "leadCopy":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          primaryButton: block.primaryButton,
          secondaryButton: block.secondaryButton,
          backgroundWarpedImage: block.backgroundWarpedImage,
        });
        break;
      case "copyWithImage":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          label: block.label,
          copy: block.copy,
          link: block.button,
          buttonStyle: block.buttonStyle,
          images: block.image.map(resolveImage),
          flipped: reverse,
          secondaryButton: block.linkObject,
        });
        break;
      case "copyImageSignature":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          images: [resolveImage(block.image)],
          flipped: reverse,
          copyFooterImage: resolveImage(block.copyFooterImage),
        });
        break;
      case "copyPixel":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          images: [resolveImage(block.image)],
          flipped: reverse,
        });
        reverse = !reverse;
        break;
      case "copyVideo":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          images: [resolveImage(block.image)],
          flipped: reverse,
          videoUrl: block.videoUrl,
          loop: block.loop,
          loopTimes: block.loopTimes,
        });
        break;
      case "highlightCaseStudy":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          ...block.selectedEntry?.[0],
          image: resolveImage(block.selectedEntry?.[0]?.image0),
          backgroundGradientColor: block.backgroundGradientColor,
        });
        headingAbove = false;
        break;
      case "cta":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          description: block.description,
          button: block.linkObject,
        });
        headingAbove = false;
        break;
      case "newestEntries":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          contentType: block.contentType,
          button: block.linkObject,
          isDark,
        });
        headingAbove = false;
        break;
      case "cardGrid":
        reverse = !false;
        resolvedblocks.push({
          type,
          uid,
          headingAbove,
          cards: block.cards?.map((card, _i, arr) => {
            return {
              image: resolveImage(card.image),
              label: card.label,
              title: card.heading,
              description: card.description,
              url: card.linkObject?.url,
              linkText: card.linkObject?.text,
              isDark,
              coloredVariant: block.coloredVariant,
              showCircles: block.showCircles,
              first: _i === 0,
              last: arr.length === _i + 1,
            };
          }),
          button: block.linkObject,
          headerSize: block.headerSize,
        });
        headingAbove = false;
        break;
      case "heading":
        reverse = !false;
        headingAbove = true;
        resolvedblocks.push({
          type,
          uid,
          label: block.label,
          heading: block.heading,
          description: block.description,
          headingSize: block.headingSize,
          headingColor: block.headingColor,
        });
        break;
      case "video":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          videoUrl: block.videoUrl,
          loop: block.loop,
          loopTimes: block.loopTimes,
          headingAbove,
        });
        headingAbove = false;
        break;
      case "list":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          values: block.values?.map(col => col.value),
          headingAbove,
        });
        headingAbove = false;
        break;
      case "listCollapsible":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          listItems: block.listItems,
          gradientColor: block.gradientColor,
          showFaqHeaders: block.showFaqHeaders,
          headingAbove,
        });
        headingAbove = false;
        break;
      case "companyImagesCarousel":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          images: block.images?.map(resolveImage),
          headingAbove,
          isDark,
        });
        headingAbove = false;
        break;
      case "buttonList":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          buttons: block.buttons?.map(button => {
            return {
              ...button.linkObject,
              style: button.style,
            };
          }),
          headingAbove,
        });
        headingAbove = false;
        break;
      case "iconGrid":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          headingColor: block.headingColor,
          textAlign: block.textAlign,
          gridItems: block.gridItems?.map(item => {
            return {
              uid: item.uid,
              svg: item.icon?.[0]?.svgCode,
              heading: item.heading,
              description: item.description,
            };
          }),
          headingAbove,
        });
        headingAbove = false;
        break;
      case "teamGrid":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          people: block.people?.map(person => {
            return {
              uid: person.uid,
              name: person.fullName,
              title: person.metaTitle,
              bio: person.descriptor0,
              image: resolveImage(person.image0),
            };
          }),
          headingAbove,
        });
        headingAbove = false;
        break;
      case "recentJobs":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          values: block.values?.map(value => value.value),
          amountOfJobs: block.amountOfJobs,
          headingAbove,
          subheading: block.valuesHeading,
        });
        headingAbove = false;
        break;
      case "timeline":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          isDark,
          milestones: block.milestones?.map(milestone => {
            return {
              ...milestone,
              image: resolveImage(milestone.image),
            };
          }),
          headingAbove,
        });
        headingAbove = false;
        break;
      case "testimonialsCarousel":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          slides: block.testimonials?.map(slide => {
            return {
              quote: slide?.descriptor0,
              author: slide?.title,
              title: slide?.metaTitle,
              headshot: resolveImage(slide?.image0),
            };
          }),
          headingAbove,
          isDark,
        });
        headingAbove = false;
        break;
      case "valuesCarousel":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          slides: block.slides?.map(slide => {
            return {
              heading: slide?.heading,
              description: slide?.description,
              videoUrl: slide?.videoUrl,
              loop: slide?.loop,
              loopTimes: slide?.loopTimes,
              image: resolveImage(slide?.image),
              bgImage: resolveImage(slide?.backgroundImage),
            };
          }),
          isDark,
        });
        break;
      case "copy":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "statsSection":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          stats: block.stats,
        });
        break;
      case "listWHeadings":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          lists: block.lists,
          headingAbove,
        });
        headingAbove = false;
        break;
      case "imageSection":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          image: resolveImage(block.image),
          headingAbove,
        });
        headingAbove = false;
        break;
      case "imageGrid":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          images: block?.images,
          headingAbove,
        });
        headingAbove = false;
        break;
      case "fullWidthImageWCopy":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          description: block.description,
          backgroundImage: resolveImage(block.backgroundImage),
          backgroundVideoUrl: block.backgroundVideoUrl,
          loop: block.loop,
          loopTimes: block.loopTimes,
          count,
          color: i % 2 === 0 ? "purple" : "pink",
          siblingAbove: array?.[i - 1 || 0]?.type === "fullWidthImageWCopy",
        });
        // count =
        //   array?.[i + 1 || 0]?.type === "fullWidthImageWCopy" ? count + 1 : 1;
        headingAbove = false;
        break;
      case "triggerThemeSwitch":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          theme: block.theme,
        });
        break;
      case "testimonialsCarouselBasic":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          slides: block.testimonials?.map(slide => {
            return {
              quote: slide?.descriptor0,
              author: slide?.title,
              title: slide?.metaTitle,
              headshot: resolveImage(slide?.image0),
            };
          }),
          headingAbove,
          isDark,
        });
        headingAbove = false;
        break;
      case "leakyFaucet":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          items: block.items,
        });
        break;
      case "testimonialAlumniSpotlight":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          testimonial: {
            quote: block.testimonial[0].descriptor0,
            author: block.testimonial[0]?.title,
            title: block.testimonial[0]?.metaTitle,
            headshot: resolveImage(block.testimonial[0]?.image0),
          },
          headingAbove,
          isDark,
        });
        break;
      case "locationsCarousel":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          locations: block.locations.map((loc, locI) => ({
            ...loc,
            image: resolveImage(loc.image),
            index: locI,
          })),
        });
        break;
      case "horizontalRule":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
        });
        break;
      case "financialTable":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          table: block.table,
        });
        break;
      case "videoScrollingHeading":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          subheading: block.subheading,
          videoUrl: block.videoUrl,
          loop: block.loop,
          loopTimes: block.loopTimes,
        });
        break;
      case "imageSplitGrid":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          items: block.imagesAndHeading.map(col => ({
            uid: col.uid,
            heading: col.heading,
            images: col.images.map(resolveImage),
          })),
        });
        break;
      case "statsCircle":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          stats: block.stats,
        });
        break;
      case "ctasGradient":
        reverse = false;
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          ctas: block.ctas,
        });
        break;
      case "newsletterForm":
        resolvedblocks.push({
          ...block,
        });
        break;
      case "embedCode":
        resolvedblocks.push({
          ...block,
        });
        break;
      case "copyLogos":
        reverse = !reverse;
        resolvedblocks.push({
          ...block,
          logos: block.logos?.map(l => {
            return {
              ...l,
              logo: resolveImage(l?.logo),
            };
          }),
          flipped: reverse,
        });
        break;
      case "formAssemblyForm":
        resolvedblocks.push({
          ...block,
        });
        break;
      default:
        resolvedblocks.push({ type, uid, ...block });
        break;
    }
  });

  const resolveThemeSwitcher = (resolvedArray = []) => {
    const uids = [];
    const triggers = resolvedArray
      ?.map((b, i) => {
        if (b.type === "triggerThemeSwitch") return i;
        return null;
      })
      ?.filter(b => b !== null);

    triggers?.forEach((triggerLocation, i, array) => {
      const firstElement = triggerLocation + 1;
      const nextTrigger = array?.[i + 1] || resolvedArray?.length;
      const children = resolvedArray?.slice(firstElement, nextTrigger);
      children.forEach(c => uids.push(c?.uid));
      // eslint-disable-next-line no-param-reassign
      resolvedArray[triggerLocation] = {
        ...resolvedArray[triggerLocation],
        children,
      };
    });

    return resolvedArray.filter(t => !uids.includes(t?.uid));
  };

  return resolveThemeSwitcher(resolvedblocks);
};

export default resolvePageBuilder;
