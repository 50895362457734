import React from "react";
import NewsletterForm from "./NewsletterForm";

const CbNewsletterForm = props => {
  return (
    <div className="my-6 md:my-10">
      <NewsletterForm {...props} />
    </div>
  );
};

export default CbNewsletterForm;
