import React from "react";
import clsx from "clsx";
import { Text, Container, Image, Icon } from "@atoms";

const TestimonialGradient = ({ quote, author, title, headshot, spotlight }) => {
  return (
    <div
      className={clsx({
        "px-0 md:px-6 lg:px-20": !spotlight,
      })}
    >
      <Container className="relative py-10">
        <div
          className={clsx(
            "relative grid grid-cols-1 px-4 sm:gap-8 md:grid-cols-2 md:px-14",
            {
              "bg-gradient-to-t from-pink to-orange": !spotlight,
              "bg-gradient-to-tl from-pink from-20% to-purple to-80%":
                spotlight,
            }
          )}
        >
          {spotlight && (
            <div className="pointer-events-none absolute inset-0 bg-spotlight-text bg-contain bg-no-repeat" />
          )}
          <div
            className={clsx(
              "relative -top-8 aspect-square h-40 bg-purple md:aspect-[7/9] md:h-[calc(100%+4rem)] md:w-80",
              {
                "left-12 md:left-0 md:order-last md:mx-auto": spotlight,
              }
            )}
          >
            <Image
              key={headshot.uid}
              image={headshot}
              fill
              ixParams={
                spotlight
                  ? {}
                  : {
                      fit: "facearea",
                      facepad: 9,
                    }
              }
            />
          </div>

          <figure
            className={clsx("pb-6 md:py-12", {
              "ml-12 flex flex-col-reverse justify-center gap-4 md:ml-8":
                spotlight,
            })}
          >
            <blockquote className="mb-6 flex flex-row items-start">
              {!spotlight && (
                <span className="-mt-2 mr-3 shrink-0" aria-hidden="true">
                  <Icon name="quote" className="w-8 text-lavender" />
                </span>
              )}
              <Text
                variant={spotlight ? "sm" : "lg"}
                className="prose font-medium leading-tight text-white"
              >
                {quote}
              </Text>
            </blockquote>
            <figcaption
              className={clsx({
                "flex justify-end": !spotlight,
              })}
            >
              <span>
                <Text
                  variant={spotlight ? "h6" : "body"}
                  className="text-white"
                >
                  {author}
                </Text>
                <Text variant="sm" className="mt-0.5 text-lavender">
                  {title}
                </Text>
              </span>
            </figcaption>
          </figure>
        </div>
      </Container>
    </div>
  );
};

export default TestimonialGradient;
