import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllJobsQuery {
    craft {
      entries(section: "jobs") {
        ... on Craft_jobs_default_Entry {
          title
          descriptor0
          url
          jobLocation
          locations {
            title
          }
          jobType
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const jobs = craft.entries?.map(job => {
    return {
      title: job?.title,
      description: job?.descriptor0,
      url: job?.url,
      locations: job?.locations || job?.jobLocation,
      jobType: job?.jobType,
    };
  });

  return jobs;
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
